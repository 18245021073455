@import "./components/spacing/functions";
@import "./components/footer/mixins";

/*
==================================================
    /Layout
==================================================
*/
.contained {
  padding-left: get-contained-side-spacing('md');
  padding-right: get-contained-side-spacing('md');
}

.hidden-md {
  display: none;
}

/*
==================================================
    /Contact page
==================================================
*/
.contact-form {
  display: flex;
  flex-wrap: wrap;

  & > .form-group {
    &:nth-of-type(1), &:nth-of-type(3) {
      padding-right: 8px;
    }

    &:nth-of-type(2), &:nth-of-type(4) {
      padding-left: 8px;
    }

    width: 50%;
    box-sizing: border-box;

    &:nth-of-type(5) {
      width: 100% !important;
    }
  }
}

/*
==================================================
    /Footer
==================================================
*/
.footer-brand-container {
  @include footer-brand-container-md;
}

/*
==================================================
    /Home page/Landing
==================================================
*/
.landing-container {
  width: calc(5 / 8 * 100%);
  margin: 0 auto;
}

/*
==================================================
    /Home page/Alsace
==================================================
*/
.alsace {
  width: calc(6 / 8 * 100%);
  margin: 0 auto;
}

/*
==================================================
    /Home page/Alsace
==================================================
*/
.discover-card {
  margin: 0 auto;
  width: calc(3 / 8 * 100%);
}

.discover-map {
  height: 53vh;
}

.discover {
  padding-top: 40vh;
}

/*
==================================================
    /Home page/Houses
==================================================
*/
.card {
}


.houses {
  width: calc(4 / 8 * 100%);
  margin: 0 auto;
}

.houses-header {
}

/*
==================================================
    /House/Other houses
==================================================
*/
.other-houses-body, .other-houses-header {
  width: calc(6 / 8 * 100%);
  margin: 0 auto;
}

/*
==================================================
    /Booking form
==================================================
*/
.booking-form {

  display: flex;
  flex-wrap: wrap;

  & > .form-group {
    width: 50%;
    box-sizing: border-box;


    &:nth-of-type(odd) {
      padding-right: 8px;
    }

    &:nth-of-type(even) {

      padding-left: 8px;
    }

    &:last-of-type {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/*
==================================================
    /Login
==================================================
*/
.login {
  width: 60%;
  margin: 0 auto;
}
